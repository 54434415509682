import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { TableService } from './table.service';
import { UtilsService } from './utils.services';
const EXCEL_EXTENSION = '.xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
// import * as campos_fechas from 'src/assets/json/campos_fechas.json';


@Injectable()
export class ExcelExportService {

  LEGALIZACION: number = 1;
  PROMESA: number = 2;
  SUBSIDIO: number = 3;
  SEGUIMIENTO_SUBSIDIO: number = 4;
  ENTREGA: number = 5;
  DESEMBOLSO: number = 6;
  RECAUDO: number = 7;
  TRAZABILIDAD: number = 8;
  ORDENES: number = 9;
  RENOVACION: number = 10;
  ESCRITURACION: number = 11;
  RIESGO: number = 12;
  RENEGOCIACION: number = 13;
  DESISTIMIENTOS: number = 14;
  PAZYSALVOS: number = 15;
  MACRO360: number = 16;
  INTERNACIONAL: number = 17;

  constructor(
    private _utilsService: UtilsService,
    private _tableService: TableService
  ) { }
  public async exportAsExcelFile(json: any[], excelFileName: string, reportType: number): Promise<void> {

    console.log('contendio a exportar reportType', reportType);
    json = json.map((element: any) => {
      var keys: any = Object.keys(element);
      // console.log(keys);
      if (keys.length > 0) {
        keys.forEach((key: any) => {
          // console.log(key);
          // element[key] = evt[key];
          if (this.isColumnDate(key) && element[key] != null) {
            let fechaFormat: Date = new Date(element[key]);
            element[key] = (fechaFormat.getDate() < 10 ? '0' + fechaFormat.getDate() : fechaFormat.getDate()) + '-' + (fechaFormat.getMonth() < 9 ? '0' + (fechaFormat.getMonth() + 1) : (fechaFormat.getMonth() + 1)) + '-' + fechaFormat.getFullYear();
          }
        })
      }

      return element;
    })

    let data: any = await this._tableService.getHeaders(reportType.toString()).toPromise();
    console.log('####headerssss data', data);

    // this._tableService.getHeaders(reportType.toString())
    //   .subscribe(
    //     (data: any) => {
    console.log('####headerssss ', data);

    data = data.data;

    let headers = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element != 'UNI_ID' && element != 'VTA_ID' && element != 'PRY_ID' && element != 'ID_PA') {
        headers.push(element);
      }
    }
    console.log('####headerssss ', headers);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { header: headers });
    const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName + '_' + this.getReportName(reportType));
        // }
        // , err => {
        //   console.log('####headerssss ', err);
        // }
      // )

  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  private isColumnDate(name: string) {
    for (let index = 0; index < this._utilsService.camposFecha.length; index++) {
      const element = this._utilsService.camposFecha[index];
      if (element == name) {
        return true;
      }
    }
    return false;
  }

  protected getReportName(id: number): string {

    if (id == this.LEGALIZACION) {
      return 'legalizacion';
    } else if (id == this.PROMESA) {
      return 'promesas';
    } if (id == this.SUBSIDIO) {
      return 'subsidios';
    } if (id == this.SEGUIMIENTO_SUBSIDIO) {
      return 'segumientos Subsidio';
    } if (id == this.ENTREGA) {
      return 'entregas';
    } else if (id == this.DESEMBOLSO) {
      return 'desembolsos';
    } if (id == this.RECAUDO) {
      return 'recaudos';
    } else if (id == this.TRAZABILIDAD) {
      return 'trazabilidad';
    } else if (id == this.ORDENES) {
      return 'ordenes';
    } else if (id == this.RENOVACION) {
      return 'renovaciones';
    } else if (id == this.ESCRITURACION) {
      return 'escrituraciones';
    } else if (id == this.RIESGO) {
      return 'riesgos';
    } else if (id == this.RENEGOCIACION) {
      return 'renegociacion';
    } else if (id == this.DESISTIMIENTOS) {
      return 'desistimientos';
    } else if (id == this.PAZYSALVOS) {
      return 'pazysalvos';
    } else if (id == this.MACRO360) {
      return 'macro360';
    } else if (id == this.INTERNACIONAL) {
      return 'internacional';
    }

    return '';
  }
}
